.rs-anim-fade.rs-anim-in {
  right: 20%;
  width: 18%;
}

.status-tab {
}

.status-tab ul {
  display: flex;
  gap: 0px;
  margin-bottom: 16px;
}

.status-tab ul li {
  padding: 9px 12px;
  border-radius: 5px 5px 0px 0px;
  cursor: pointer;
  border-bottom: 1px solid #dddfe4;
  font-size: 14px;
}

.status-tab ul li.active {
  border-bottom: 1px solid#000;
  color: #000;
  font-weight: 600;
}

.consultancy-dashboard {
  width: 100%;
  height: 90%;
  overflow: hidden;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  border-radius: 8px;
  max-width: 1200px;
  margin: auto;
}
.cs-title {
  color: #191919;
  font-size: 28px;
}
.cs-form-container {
  width: 452px;
  height: 414px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 64px 36px;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 0px 23.3px 18px rgba(241, 241, 241, 0.12);
}
.cs-form-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0px;
  position: relative;
}
.cs-form-label {
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 6px;
}
.cs-input-form {
  width: 100%;
  max-width: 380px;
  border: 1px solid #dddfe4;
  height: 48px;
  padding: 0px 12px;
  border-radius: 4px !important;
  resize: none;
}
.cs-submit-button {
  color: #fff;
  text-decoration: none;
  height: 48px;
  max-width: 380px;
  display: flex;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  padding: 0px 14px;
  border: 1px solid #191919;
  box-shadow: none !important;
  cursor: pointer;
  width: 100%;
  background: #191919;
  margin-top: 8px;
  font-size: 18px;
}
.cs-banner-container {
  flex-basis: 60%;
  height: 40vh;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 32px;
  box-sizing: border-box;
  pointer-events: none;
  background-image: url("../../assets/images/others/Search-Banner.png");
  background-repeat: no-repeat;
  background-size: 500px, 500px;
  background-position: 50% 50%;
}

.cs__error__message {
  position: absolute;
  bottom: -15px;
  font-size: 11px;
  color: red;
  left: 0px;
}

.button-loader {
  display: flex;
  border: 4px solid #fff;
  border-top: 4px solid green;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin: 25px;
  animation: spin 2s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
