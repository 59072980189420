.login-logo {
  position: relative;
  left: 0;
  right: 0;
  text-align: center;
  top: 110px;
}

.login-form {
  background-color: #fff;
  border-radius: 4px;
  padding: 40px 40px;
  padding-bottom: 20px;
  width: 400px;
  margin: 10% auto;
}

.login-form h3 {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 30px;
}

.login-form .input-group {
    width: 100%;
    position: relative;
}

.login-form .btn-blue {
  width: 100%;
}

.login-form .btn-text {
  padding: 10px;
  text-align: center;
  display: block;
  margin: 0 auto;
}

.message {
    color: #ee3811;
    font-size: 12px;
}
