@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500&display=swap");

body {
  margin: 0px;
  padding: 0px;
  background-color: #f1f3f5 !important;
  font-family: "Roboto", sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* text-transform: capitalize; */
}

.dflex {
  display: flex;
}

.hide {
  display: none !important;
}

ul,
h1,
h2,
h3,
h4,
h5,
h6,
label {
  margin: 0px;
  padding: 0px;
}

ul {
  list-style: none;
}

.width30 {
  width: 30%;
}

/* sidebar-wrapper */
.main-wrapper {
  display: flex;
  width: 100%;
  height: calc(100vh - 63px);
}

.sidebar-wrapper {
  display: flex;
  width: fit-content;
  position: fixed;
  top: 61px;
  z-index: 9;
}

.sidebar_admin {
  display: flex;
  width: fit-content;
  position: relative;
  top: 56px;
  z-index: 9;
}

.main-body {
  width: 100%;
  padding: 16px 30px;
  /* margin-left: 265px; */
  top: 61px;
  position: relative;
  overflow: auto;
}

/* table-view */
.table-view {
  background-color: #fff;
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 12px;
  text-transform: capitalize;
}

.table {
  width: 100%;
  text-align: left;
  border-radius: 4px;
  border-spacing: 0;
  border-collapse: collapse;
}

.table tr th {
  border-bottom: 0px solid #dddfe4;
  background-color: #f1f3f5;
  padding: 16px 14px;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 400;
  color: #353a3f;
}

.table tr td {
  border-bottom: 1px solid #dddfe4;
  padding: 16px 14px;
  color: #0a0a0b;
  font-size: 14px;
  font-weight: 400;
  vertical-align: middle;
}

.table .grey {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
  padding: 6px 8px;
  border-radius: 4px;
  font-size: 13px;
}

.table tr td .editIcon {
  max-width: 20px;
  cursor: pointer;
  padding-left: 5px;
  float: right;
}

.table tr td .deleteIcon {
  max-width: 20px;
  cursor: pointer;
  float: right;
}

.table .p_name {
  color: #1f75cb;
  padding-bottom: 0px;
  text-decoration: none;
}

/* list view */
.list-view {
  background-color: #fff;
  border-radius: 4px;
  padding: 20px 30px;
  padding-bottom: 0px;
  margin-bottom: 20px;
}

.list-view h4 {
  font-size: 18px;
  font-weight: 500;
  padding-bottom: 8px;
  border-bottom: 1px solid #dddfe4;
  margin-bottom: 15px;
}

.list-view .title p {
  margin: 0;
  padding: 2px 12px;
  background-color: #2c2758;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.list-view .title h4 {
  padding: 0;
  border: none;
  margin: 0;
  line-height: normal;
}

.list-view .title {
  padding-bottom: 8px;
  border-bottom: 1px solid #dddfe4;
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
}

.list-view ul {
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.list-view ul li {
  width: 23%;
  margin-bottom: 40px;
}

.list-view ul li label {
  margin-bottom: 3px;
  color: #353a3f;
  display: block;
  font-weight: 600;
  font-size: 15px;
}

.list-view ul li span {
  color: #0a0a0b;
  font-weight: 400;
  font-size: 14px;
}

.list-view .noInfoAdded {
  color: #0a0a0b;
  font-weight: 400;
  font-size: 14px;
}

/* tabs-view */
.tabs-view ul {
  display: flex;
}

.tabs-view ul li {
  width: auto;
}

/* pagination */
.pagination {
  display: flex;
  justify-content: center;
  padding: 30px;
  padding-bottom: 0px;
}

.pagination a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  border: 1px solid #ddd;
}

.pagination a.active {
  background-color: #2d2759;
  color: white;
  border: 1px solid #2d2759;
}

.pagination a:hover:not(.active) {
  background-color: #ddd;
}

.pagination a:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.pagination a:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

/* search */
.search {
  display: flex;
}

.search-input {
  position: relative;
}

.search-input img {
  position: absolute;
  left: 5px;
  top: 8px;
}

.search input {
  background-color: #fff;
  border: 1px solid #dddfe3;
  border-radius: 4px;
  height: 36px;
  width: 250px;
  padding: 0px 12px;
  padding-left: 32px;
  outline: 0 !important;
  box-shadow: none !important;
  margin-right: 12px;
}

/* btn */
.btn-blue {
  background-color: #191919;
  color: #fff;
  text-decoration: none;
  height: 36px;
  display: flex;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  padding: 0px 14px;
  border: 1px solid #191919;
  box-shadow: none !important;
  cursor: pointer;
  min-width: 120px;
}

.btn-red {
  background-color: #dc3545;
  color: #fff;
  text-decoration: none;
  height: 36px;
  display: flex;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  padding: 0px 14px;
  border: 1px solid #dc3545;
  box-shadow: none !important;
  cursor: pointer;
}

.btn-cancel {
  background-color: #6c757d;
  color: #fff;
  text-decoration: none;
  height: 36px;
  display: flex;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  padding: 0px 14px;
  border: 1px solid #6c757d;
  box-shadow: none !important;
  cursor: pointer;
}

.btn-blue:hover {
  background-color: #353535;
  border: 1px solid #353535;
  color: #ffff;
  text-decoration: none;
}

.btn-grey {
  color: #000;
  text-decoration: none;
  height: 36px;
  display: flex;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  padding: 0px 14px;
  background-color: #f1f1f1;
  border: 1px solid #f1f1f1;
  box-shadow: none !important;
  cursor: pointer;
  min-width: 120px;
}

.pop_button {
  margin: 2px;
}

.text-btn {
  color: #000;
  text-decoration: none;
  height: 36px;
  display: flex;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  padding: 0px 14px;
  background-color: #f1f1f1;
  border: 1px solid #f1f1f1;
  box-shadow: none !important;
  cursor: pointer;
}

/* back-to */
.back-to {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.back-to img {
  margin-right: 5px;
}

.back-to span {
  color: #000;
}

.till-active {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
  padding: 3px 6px;
  border-radius: 4px;
}

.not-active {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  padding: 3px 6px;
  border-radius: 4px;
}

/* header-main */
.header-main {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.h-heading h4 {
  font-size: 20px;
  color: #000;
  font-weight: 500;
}

/* steps */
.steps {
  margin-bottom: 40px;
}

.steps ul {
  display: flex;
  justify-content: space-between;
}

.steps ul li {
  width: 25%;
  margin: 0 auto;
  text-align: center;
  position: relative;
}

.steps ul li::after {
  content: "";
  position: absolute;
  top: 14px;
  width: 70%;
  height: 1px;
  background-color: #dddfe4;
  margin-left: -10px;
}

.steps ul li:last-child::after {
  display: none;
}

.steps ul li label {
  background-color: #f1f1f1;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-bottom: 12px;
}

.steps ul li span {
  font-size: 15px;
  font-weight: 400;
}

.steps ul li.active label {
  color: #fff;
  background-color: #2d2759;
}

.steps ul li.active span {
  color: #2d2759;
  font-weight: 500;
}

.steps ul li.active::after {
  background-color: #2d2759;
}

/* input-group */
.form-wrapper {
  margin-top: 24px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.input-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.mt-top-align {
  margin-top: 28px;
}

.lable-flex {
  display: flex !important;
  justify-content: space-between;
}

.input-group {
  margin-bottom: 25px;
  width: 30%;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  flex-direction: column;
}

.input-group-over-write {
  height: 95px;
}

.input-group-textarea {
  margin-bottom: 24px;
  width: 45%;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  flex-direction: column;
  resize: none;
}

.input-group-textarea textarea.input-form {
  min-height: 100px;
  padding: 12px 12px;
  resize: none;
}

.input-group-textarea .input-form {
  width: 100%;
  border: 1px solid #dddfe4;
  height: 36px;
  padding: 0px 12px;
  border-radius: 4px !important;
}

.input-check {
  display: flex;
  gap: 5px;
  align-items: center;
  margin-bottom: 6px;
}

.input-group .input-check label {
  margin: 0;
  cursor: pointer;
}

.input-group label {
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 6px;
  display: block;
}

.input-group .input-form {
  width: 100%;
  border: 1px solid #dddfe4;
  height: 36px;
  padding: 0px 12px;
  border-radius: 4px !important;
  resize: none;
}

.input-group textarea.input-form {
  min-height: 100px;
  padding: 12px 12px;
}

.group-input {
  display: flex;
  justify-content: space-between;
}

.group-input .input-group {
  width: 48% !important;
  height: 23vh !important;
}

input[type="file"] {
  width: 100%;
}

/* over writer */
.react-date-picker__wrapper {
  border: none !important;
  font-size: 14px;
  font-weight: 400;
}

.react-date-picker__button {
  display: none;
}

/* select */
select {
  -moz-appearance: none;
  /* Firefox */
  -webkit-appearance: none;
  /* Safari and Chrome */
  appearance: none;
  width: 100%;
  border: 1px solid #dddfe4;
  height: 36px;
  padding: 0px 12px;
  border-radius: 4px;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 5px;
  cursor: pointer;
}

.inputgrouping {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.inputgrouping .input-form {
  width: 100%;
}

.subh-heading {
  display: flex;
  justify-content: space-between;
}

.subh-heading label {
  font-size: 15px;
  color: #0780fe;
  cursor: pointer;
}

.btn-section {
  display: flex;
  justify-content: flex-end !important;
}

.clear_button_left {
  margin: 0;
}

.clear_button_left button {
  margin: 0 !important;
}

.btn-section button {
  margin-left: 15px;
}

.list-view .address-info li {
  width: 50%;
}

.disabled {
  pointer-events: none;
  background-color: #e0e0e0;
}

.required {
  color: red;
}

.err-text {
  color: red;
  font-size: 11px;
  padding: 2px;
}

.cursor-pointer {
  cursor: pointer;
}

.err-border {
  border: 1px solid red !important;
}

.close-container {
  display: flex;
  align-items: flex-start;
}

.remark-area {
  margin-bottom: 24px;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  flex-direction: column;
  width: 100%;
}

.remark_level2 {
  margin-bottom: 0;
}

.remarks-field {
  margin-bottom: 24px;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  flex-direction: column;
  width: 100% !important;
  min-height: 100px;
  padding: 12px 12px;
  resize: none;
  border: 1px solid #dddfe4;
}

.ellipsis {
  max-width: 235px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.edit-popup {
  width: 80px;
  height: 50px;
  top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 40px;
  background: #fafafa;
  border-radius: 4px;
  cursor: default;
  border: 1px solid #e6eef7;
  z-index: 9;
  gap: 10px;
  padding: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.rs-anim-fade.rs-anim-in {
  height: 100%;
  z-index: 111111111 !important;

  outline: none !important;
}

.w-100 {
  width: 100% !important;
}

.h--50 {
  height: 50px !important;
}

.flex {
  width: 48%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rs-btn-primary {
  background-color: #2c2758 !important;
  color: #fff !important;
  box-shadow: none !important;
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  background-color: #2c2758 !important;
}

.rs-picker-toggle-read-only {
  opacity: 1 !important;
  color: #2c2758 !important;
}

.rs-picker-toggle-read-only:hover {
  opacity: 1 !important;
  color: #2c2758 !important;
}

.rs-picker-toggle-textbox:focus {
  border: 1px solid #dddfe4 !important;
}

.rs-btn {
  height: 40px !important;
  border: 1px solid #dddfe4 !important;
}

.rs-btn:focus {
  height: 40px !important;
  border: 1px solid #dddfe4 !important;
  box-shadow: none !important;
}

.rs-picker-toggle-active {
  border: 1px solid #dddfe4 !important;
}

.rs-anim-fade.rs-anim-in {
  height: auto;
}

.rs-picker-default .rs-picker-toggle.rs-btn::after {
  border: 1px solid #dddfe4 !important;
}

.rs-picker-toggle-active,
.rs-picker.rs-picker-focused {
  box-shadow: none !important;
}

.Badge-button {
  background-color: rgb(220, 218, 218) !important;
  color: rgb(45, 44, 44);
  text-decoration: none;
  height: 36px;
  border-radius: 4px;
  position: relative;
  border: 1px solid rgb(160, 160, 162);
  border-radius: 4px;
  padding: 0px 14px;
  box-shadow: none !important;
  cursor: pointer;
  min-width: 120px;
  user-select: none;
  transition: all 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}

.Badge-button:hover {
  /* transform: scale(1.03); */
  background-color: rgb(224, 220, 220) !important;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.15);
}

.Badge-button:active {
  transform: scale(0.96);
}

.Badge-button .badge {
  transform: scale(1.2);
}

.badge {
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: block;
  position: absolute;
  color: #2c2758;
  background-color: rgb(224, 220, 220) !important;
  border: 2px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -15px;
  right: -15px;
  transition: all 0.3s;
}

.pppp {
  color: #0a0a0b;
  font-weight: 400;
  font-size: 14px;
}

.pdt-10 {
  padding-top: 10px !important;
}

.pdt-15 {
  padding-top: 15px !important;
}

.m-5 {
  color: #464646;
  background-color: #dad8d8;
  /* border-color: #c3e6cb; */
  padding: 1px 3px;
  border-radius: 4px;
  margin: 5px !important;
}

.td-table {
  width: 14%;
  height: auto;
  word-wrap: normal;
}

.sub-p {
  font-size: 12px;
  color: #868889;
}

.sub-p2 {
  font-size: 12px;
  color: #dc3545;
}

.status-pp {
  height: 30px;
  width: 180px;
  padding: 5px;
  text-align: center !important;
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
  border-radius: 4px;
}

.color-btn {
  background-color: rgb(220, 218, 218) !important;
}

.CD-Dash {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.CD-select {
  width: 100%;
}

.hhh100 {
  width: 170px !important;
  height: 40px !important;
}

.rs-popover {
  top: 50px !important;
  padding: 1px !important;
  box-shadow: none !important;
  border: 1px solid rgb(190, 189, 190) !important;
  position: fixed !important;
  background-color: #fff;
}

.rs-popover.placement-bottom > .rs-popover-arrow {
  display: none;
}

.fff {
  background-color: rgba(232, 232, 235, 0.571) !important;
}

/* margins */
.mm-5 {
  margin: 5px !important;
}

.mm-10 {
  margin: 10px !important;
}

.mm-15 {
  margin: 15px !important;
}

.mm-20 {
  margin: 20px !important;
}

.mmt-5 {
  margin-top: 5px !important;
}

.mmt-10 {
  margin-top: 10px !important;
}

.mmt-15 {
  margin-top: 15px !important;
}

.mmt-20 {
  margin-top: 20px !important;
}

.mmb-5 {
  margin-bottom: 5px !important;
}

.mmb-10 {
  margin-bottom: 10px !important;
}

.mmb-15 {
  margin-bottom: 15px !important;
}

.mmb-20 {
  margin-bottom: 20px !important;
}

.mml-5 {
  margin-left: 5px !important;
}

.mml-10 {
  margin-left: 10px !important;
}

.mml-15 {
  margin-left: 15px !important;
}

.mml-20 {
  margin-left: 20px !important;
}

.mmr-5 {
  margin-right: 5px !important;
}

.mmr-10 {
  margin-right: 10px !important;
}

.mmr-15 {
  margin-right: 15px !important;
}

.mmr-20 {
  margin-right: 20px !important;
}

.heading-section {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.heading-section h4 {
  font-size: 20px;
  color: #000;
  font-weight: 500;
}

.multiSelectContainer {
  width: 100% !important;
  border-radius: 4px !important;
  border-style: none !important;
  position: relative;
}

.multiSelectContainerErr {
  width: 100% !important;
  border-radius: 4px !important;
  border-style: none !important;
  border: 1px solid #dc3545 !important;
}

.detail-tabs {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.detail-tabs div {
  display: flex;
  align-items: center;
  gap: 48px;
}

.detail-tabs h2 {
  cursor: pointer;
  font-size: 20px;
}

.detail-tabs .active {
  border-bottom: 1.5px solid #3c3665;
  color: #3c3665;
}

.pipeline {
  padding: 20px;
  background-color: #fff;
  border-radius: 6px;
}

.pipelineDetails {
  border-bottom: 1px solid #dddfe4;
  padding-left: 24px;
  margin-bottom: 24px;
}
.pipelineDetails .headder {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.headder img {
  margin-right: 15px;
  cursor: pointer;
}

.pipelineDetails h4 {
  font-size: 18px;
  position: relative;
  padding-bottom: 16px;
}

.multiSelectContainerErr {
  width: 100% !important;
  border-radius: 4px !important;
  border-style: none !important;
  border: 1px solid #dc3545 !important;
}

.detail-tabs {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.detail-tabs div {
  display: flex;
  align-items: center;
  gap: 48px;
}

.detail-tabs h2 {
  cursor: pointer;
  font-size: 20px;
}

.detail-tabs .active {
  border-bottom: 1.5px solid #3c3665;
  color: #3c3665;
}

.pipeline {
  padding: 20px;
  background-color: #fff;
  border-radius: 6px;
}

.overWriteClass {
  padding: 0px;
}
.overWriteClass .pipelineDetails {
  padding-left: 0px;
  margin-bottom: 12px;
}

.overWriteClass img {
  margin-right: 0px;
}
.overWriteClass h4 {
  padding-bottom: 6px !important;
}
.overWriteClass p {
  padding-bottom: 4px !important;
}

.overWriteClass .date-wrapper {
  display: flex;
  align-items: center;
  gap: 20px;
}

.overWriteClass .date-wrapper p {
  padding-bottom: 0px !important;
}

.pipelineDetails {
  border-bottom: 1px solid #dddfe4;
  padding-left: 24px;
  margin-bottom: 24px;
}

.pipelineDetails h4 {
  font-size: 18px;
  position: relative;
  padding-bottom: 16px;
}

.pipelineDetails h4::before {
  content: "";
  width: 12px;
  height: 12px;
  border-radius: 50px;
  position: absolute;
  left: -24px;
  top: 4.5px;
}

.pipelineDetails p {
  font-size: 16px;
  padding-bottom: 12px;
  margin: 0;
}

.pipelineDetails p span {
  color: #46406d;
  margin-left: 10px;
}

.options-bar {
  cursor: pointer;
  /* padding: 4px 10px; */
  /* background-color: rgba(128, 128, 128, 0.652); */
  display: flex;
  flex-direction: column;
  align-items: center !important;
  gap: 3px;
  border-radius: 4px;
  flex-wrap: nowrap;
  z-index: 9;
}

.options-bar li {
  width: 4px !important;
  height: 4px !important;
  background: black;
  border-radius: 50%;
  margin: 0 !important;
}

.options-bar li:last-child {
  margin: 0;
}

.pd5 {
  padding: 5px;
}

.progress {
  background-color: #d8d8d8;
  border-radius: 20px !important;
  position: relative;
  margin: 15px 0;
  height: 10px;
  width: 60%;
}

.progress-done {
  background: #46406dc9;
  border-radius: 20px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 70%;
  opacity: 1;
  transition: 1s ease 0.3s;
  font-size: 10px;
  font-weight: bold;
}

.same {
  width: 100%;
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  align-items: center;
}

.same label {
  margin-top: 4px;
}

.detail-status {
  display: flex;
  gap: 6px;
  align-items: center;
}

.detail-status p {
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  padding-right: 4px;
}

.detail-status span {
  font-size: 14px;
}

.detail-status .active-emp {
  color: #33aa56;
}

.detail-status .inactive-emp {
  color: red;
}

.table-action {
  display: flex;
  margin: 0px;
  gap: 20px;
  justify-content: end;
}

.table-action li {
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  text-align: center;
  padding: 8px 0px;
}

.table-action li:hover {
  background-color: #f1f3f5;
}

.chips {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  padding: 2px 6px;
  border: 1px solid #191919;
  background-color: #191919;
  color: #fff;
  border-radius: 14px;
}

.chips svg {
  cursor: pointer;
}

.chips path {
  fill: #ffffff;
}

.chips-parent {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 15px;
}

.admin-applied-filter {
  padding-top: 20px;
}

.Title-Filter {
  font-size: 15px;
}
.user-avatar {
  background: #191919 !important;
}

.verify-section {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.verify-section p {
  font-size: 18px;
  line-height: 20px;
  color: #0780fe;
  &:hover {
    text-decoration: underline;
  }
}

.verify-section img {
  margin-left: 8px;
  display: block;
}

.report-card,
.report-card-total {
  min-width: 150px;
  flex-basis: 18%;
  min-height: 101px;
  border-radius: 8px;
  border: 1px solid #e7e7e7;
  background: #fafafa;
  padding: 12px 25px 23px 15px;
}

.report-card-total {
  background: #d2cfcfa0;
}

.report-card p,
.report-card-total p {
  color: #848484;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-bottom: 12px;
  padding-left: 0px;
}

.report-card-total p {
  font-weight: 600;
}

.report-card span,
.report-card-total span {
  color: #6b6b6b;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: block;
}

.report-card .count{
  display: inline-block;
  padding-left: 10px;
  font-weight: 500;
}

.reports-container ul {
  display: flex;
  flex-wrap: wrap;
  gap: 24px 30px;
  margin-bottom: 0px;
}

.reports-container .total-wrapper{
  justify-content: center;
  margin-bottom: 30px;
}

.rs-picker-daterange-menu
  .rs-picker-daterange-panel-show-one-calendar
  .rs-picker-toolbar {
  display: flex !important;
  padding-right: 20px;
  max-width: 100% !important;
}

.rs-stack {
  display: block !important;
}

.ellipsis-image {
  cursor: pointer;
  height: 24px;
}

.after-filter-dropdown {
  &::after {
    content: "";
    width: 100%;
    height: 19px;
    background: transparent;
    position: absolute;
    top: -17px;
    left: 0;
    cursor: pointer;
  }
}

.add-notes {
  padding: 0px 30px;
}

.add-notes p {
  margin-top: 0px !important;
}

.add-notes ul li {
  padding-bottom: 0px;
}

.add-notes ul li p {
  padding-left: 0px;
}

.add-notes label {
  margin-bottom: 3px;
  color: #353a3f;
  display: block;
  font-weight: 600;
  font-size: 15px;
}

.add-notes span {
  padding-left: 20px;
  display: block;
  padding-top: 5px;
}

.reduce-margin li {
  margin-bottom: 0px !important;
  padding-bottom: 0px;
}
