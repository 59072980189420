.heading-section {
  margin-bottom: 20px;
}

.heading-section h4 {
  font-size: 20px;
  color: #000;
  font-weight: 500;
}

.neg {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-left: 10px;
}

.neg-active {
  background-color: #155724;
}

.filter_table {
  width: 100%;
}

.filter_table_wrapper {
  width: 100%;
}

.filter_view_sidebar {
  background: #fff;
  padding: 12px 12px;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  margin-bottom: 12px;
}

.filter_view_sidebar::-webkit-scrollbar {
  width: 10px;
}

.filter_view_sidebar::-webkit-scrollbar-track {
  background: #ffffff;
}

.filter_view_sidebar::-webkit-scrollbar-thumb {
  background-color: #b4b4b4;
  border-radius: 10px;
  border: 3px solid #ffffff;
}