.header-section {
  border-bottom: 1px solid #dddfe4;
  padding: 0px 0px;
  background-color: #fff;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 99;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 55px;
}

.header-section .text-btn {
  position: absolute;
  right: 0;
  top: 42px;
}

.header-profile {
  margin-right: 24px;
  position: relative;
}

.logo-section {
  margin-left: 24px;
}

.postion-fixed {
  position: fixed;
  right: 5px;
  top: 0px;
}

.profile {
  padding: 3px 5px;
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  border-radius: 4px;
}

.rs-popover[class*='placement-bottom'].rs-anim-in {
  left: auto !important;
  right: 30px;
  width: auto;
}

.rs-avatar {
  width: 30px;
  height: 30px;
  font-size: 12px;
  color: #fff;
  border-radius: 100%;
  text-align: center;
  padding: 6px;
}

.profile.active {
  background-color: #fff;
  outline: 2px solid #2d2759;
}

.profileImg {
  max-width: 30px;
  border-radius: 50%;
}

.header-links {
  margin-left: 50px;
}

.header-align {
  display: flex;
  align-items: center;
}

.header-links ul {
  margin: 0px;
}

.header-links ul li {
  display: inline-block;
  padding: 13px 10px;
  margin-left: 20px;
}

.header-links ul li a {
  font-size: 16px;
  color: #000;
  text-decoration: none;
}

.header-links ul li.active {
  border-bottom: 1.5px solid #000;
}

.header-links ul li.active a {
  font-weight: 600;
  color: #000;
}
