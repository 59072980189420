.subsidebar-section {
    background-color: #3c3665;
    height: 100vh;
    min-width: 190px;
}

.subsidebar-section-admin {
    background-color: #3c3665;
    height: 101%;
    min-width: 190px;
    min-height: 539px;
}

.subsidebar-section h1 {
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    padding: 20px 16px;
}

.subsidebar-section ul li a {
    color: #fff;
    font-size: 15px;
    text-decoration: none;
    font-weight: 400;
    display: block;
    cursor: pointer;
}

.subsidebar-section ul li {
    color: #fff;
    font-size: 15px;
    text-decoration: none;
    font-weight: 400;
    display: block;
    padding: 12px 16px;
    cursor: pointer;
    margin-bottom: 6px;
}

.subsidebar-section ul li.active {
    background-color: rgb(255 255 255 / 5%);
}