.candidate-form .input-group {
  width: 48%;
  position: relative;
}
.candidate-form .input_level1Group {
  width: 100%;
  position: relative;
}

.candidate-form .flex {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: initial;
}

.h-title {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  gap: 30;
}

.input-group2 {
  width: 24%;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  flex-direction: row;
  position: relative;
}


.candidate-form .input-group textarea.input-form {
  padding: 5px 12px;
  resize: none;
}

.add-more {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add-more p {
  cursor: pointer;
  color: #3c3665;
  font-size: 14px;
}

.form-info {
  position: relative;
  padding: 16px;
  border: 1px solid #dddfe3;
  border-radius: 6px;
  margin-bottom: 24px;
}

.remove {
  position: absolute;
  top: -10px;
  right: -8px;
}

.remove button {
  background: #dc3545;
  padding: 4px 9px;
  border-radius: 50%;
  color: #fff;
}

.progress {
  background-color: #d8d8d8;
  border-radius: 20px !important;
  position: relative;
  height: 10px;
  width: 60%;
}

.progress-done {
  background: #46406dc9;
  border-radius: 20px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 70%;
  opacity: 1;
  transition: 1s ease 0.3s;
  font-size: 10px;
  font-weight: bold;
}

.required_label {
  color: #dc3545;
}

.required__error__message {
  color: #dc3545;
  font-size: 12px;
  padding: 2px;
}

input:invalid,
select:invalid,
textarea:invalid {
  border: 1px solid #dc3545 !important;
}

.white-wrapper {
  background-color: #fff;
  border-radius: 4px;
  padding: 30px 30px;
  margin-bottom: 12px;
}

.same {
  margin-top: 5px;
  display: flex;
  align-items: center;
  gap: 4px;
  align-items: center;
}

.same label {
  margin-top: 4px;
}

.subh-heading {
  font-size: 16px;
  font-weight: 400;
  display: flex;
  border-bottom: 1px solid #dddfe4;
  padding-bottom: 6px;
  margin-bottom: 20px;
}

.progress {
  background-color: #d8d8d8;
  border-radius: 20px !important;
  position: relative;
  margin: 15px 0;
  height: 10px;
  width: 60%;
}

.progress-done {
  background: #46406dc9;
  border-radius: 20px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 70%;
  opacity: 1;
  transition: 1s ease 0.3s;
  font-size: 10px;
  font-weight: bold;
}

.hh-50 {
  /* height: 100px; */
}

.hh-130 {
  height: 110px;
}

.w-100 {
  width: 100% !important;
}

.w-48 {
  width: 48%;
}
.w-24 {
  width: 24%;
}

.ppb-5 {
  padding-bottom: 5px !important;
}

.address-flex {
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 10px;
}
