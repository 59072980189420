.nodata-section {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 0px;
    flex-wrap: wrap;
}

.nodata-section img {
    width: 120px;
}

.nodata-section h4 {
    font-size: 16px;
    width: 100%;
    text-align: center;
}