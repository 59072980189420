.loader {
  background-color: #fff;
  width: 100%;
  height: 100vh;
  position: relative;
}
.spinner {
  width: 40px;
  height: 40px;
  margin: auto;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left: 4px solid #000;
  border-right: 4px solid #000;
  animation: spinner 1s linear infinite forwards;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}
.loader h1 {
  color: #000;
  font-size: 35px;
}
.loader h1 .loading {
  animation: extend 1s steps(3, end) infinite;
  display: inline-block;
  overflow: hidden;
  vertical-align: bottom;
  color: #000;
}
.loader h1 .loading::before {
  content: "...";
}

@keyframes extend {
  0% {
    width: 0.25em;
  }
  100% {
    width: 1em;
  }
}
.loader.small .spinner {
  top: 100px;
  left: 40%;
}
.loader.small h1 {
  top: 164px;
  left: 25%;
  font-size: 30px;
}
.container_spin {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.inner_spiner {
  width: 13%;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
