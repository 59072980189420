.cardskeleton {
  width: 100%;
}

.content {
  padding: 20px 30px;
}

.cardskeleton .content p {
  background: #eee;
  background: linear-gradient(110deg, #ececec 10%, #f5f5f5 20%, #ececec 35%);
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
}
.cardskeleton .content h2 {
  background: #eee;
  background: linear-gradient(110deg, #ececec 10%, #f5f5f5 20%, #ececec 35%);
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
}

.cardskeleton .contentTable h1 {
  background: #eee;
  background: linear-gradient(110deg, #ececec 10%, #f5f5f5 20%, #ececec 35%);
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
}

.cardskeleton .content h2 {
  height: 25px;
  width: 100%;
  margin: 5px 0;
}

.cardskeleton .contentTable h1 {
  height: 50px;
  width: 100%;
  margin: 15px 0;
}
.cardskeleton .content p {
  height: 25px;
  width: 100px;
  margin: 5px 0 10px 0;
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}
