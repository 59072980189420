ul li {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  padding-bottom: 8px;
}

ul li p {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  display: inline-block;
  padding-left: 10px;
}
.existing-user-modal {
  position: fixed;
  background-color: #01010187;
  width: 100%;
  top: 0;
  left: 0;
  height: 100vh;
  z-index: 99;
}
.existing-user-pipeline .pipeline {
  padding: 0px !important;
}
.existing-user-pipeline .pipelineDetails {
  padding: 0px !important;
}
.existing-user-pipeline .pipeline-title {
  border-bottom: 1px solid #000;
  padding: 0 0 12px !important;
}
