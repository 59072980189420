.analytics-overview {
  display: flex;
  flex-wrap: wrap;
}

.analytics-card {
  background-color: #fff;
  border-radius: 4px;
  padding: 25px 20px;
  width: 20%;
  margin-bottom: 24px;
  margin-right: 12px;
}

.analytics-card h1 {
  font-size: 17px;
  font-weight: 500;
  color: #000;
  margin-bottom: 12px;
  line-height: 140%;
}

.analytics-card label {
  font-size: 18px;
  font-weight: 400;
  color: #4f4f4f;
}

.emp-dashboard-overview {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 22px;
  flex-wrap: wrap;
}

.data-card {
  background-color: #fff;
  border-radius: 4px;
  margin-bottom: 24px;
  width: 32%;
}

.data-card-heading {
  font-size: 17px;
  font-weight: 500;
  color: #000;
  padding: 14px 20px;
  border-bottom: 1px solid #f1f1f1;
}

.data-card-heading h4 {
  font-size: 16px;
  font-weight: bold;
}

.data-card-data-wrapper {
  max-height: 320px;
  min-height: 320px;
  overflow-y: auto;
}

.data-card-data {
  padding: 14px 20px;
  border-bottom: 1px solid #f1f1f1;
}

.data-card-data:last-child {
  border-bottom: 0px solid #f1f1f1;
}

.data-card-data h4 {
  font-size: 14px;
  margin-bottom: 4px;
  color: #191919;
  font-weight: 400;
}

.data-card-data h5 {
  display: flex;
  justify-content: space-between;
}

.data-card-data h5 span {
  font-size: 13px;
  color: #191919;
  font-weight: 400;
}

.data-card-data h5 label {
  font-size: 14px;
  color: #191919;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.data-card-data h5 .ellipsis{
  display: block;
}

.candidate-stages {
  display: flex;
  gap: 36px 10px;
  background: #fff;
  padding: 16px 0;
  border-radius: 4px;
  margin-bottom: 24px;
  flex-wrap: wrap;
}
.candidate-stages div {
  max-width: 15.7%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
}
.candidate-stages p {
  margin: 0;
}
.filter-dropdown {
  position: absolute;
  right: 0px;
  top: 25px;
  background: #fafafa;
  border-radius: 4px;
  cursor: default;
  border: 1px solid #f1f3f5;
}
.filter-dropdown li {
  width: 100% !important;
  background: none;
  height: 100%;
  white-space: nowrap;
  cursor: pointer;
  border-radius: 0;
  padding: 6px 12px;
  margin: 0 !important;
}
.filter-dropdown li:hover {
  background-color: #f1f1f1;
}
.detail-status div p {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 2px;
  min-width: 120px;
  background-color: #fff;
}
.filter-dropdown label {
  margin-bottom: 8px;
}
.filter-dropdown .btn-blue {
  float: right;
  min-width: fit-content;
  height: fit-content;
  padding: 4px 8px;
  font-size: 12px;
}
.detail-status .filter-dropdown a {
  display: flex;
  gap: 8px;
  margin-bottom: 10px;
}
.date-dropdown {
  padding: 12px;
  border-radius: 4px;
}
.filter-select p {
  position: relative;
  padding: 2px 28px 2px 8px;
  border: 1px solid #e5e5ea;
  border-radius: 4px;
}
.filter-select p::after {
  content: url(../../assets/images/dropdown.svg);
  position: absolute;
  right: 0;
  top: 4px;
}
.rs-picker-default .rs-picker-toggle.rs-btn {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}


.rs-anim-fade.rs-anim-in {
  right: 30px;
}

.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
  box-shadow: inset 0 0 0 1px #2c2758 !important;
}
.rs-calendar-table-cell-in-range::before {
  background-color: #d2d2f6 !important;
  color: #fff !important;
}
.rs-calendar-table-cell-content:hover {
  background-color: #2c2758 !important;
  color: #fff !important;
}
