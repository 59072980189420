.pagination_wrapper {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.select_rowAction {
  width: 60px;
}
.select_rowAction select {
  background-color: #fff;
  outline: none;
  cursor: pointer;
}
.pagination button {
  color: black;
  padding: 8px 16px;
  text-decoration: none;
  border: 1px solid #ddd;
  background-color: #fff;
}

.pagination button.active {
  background-color: #191919;
  color: white;
  border: 1px solid #191919;
}

.pagination button:hover:not(.active) {
  background-color: #ddd;
}

.pagination button:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.pagination button:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
