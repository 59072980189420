.statusModal {
  position: fixed;
  background-color: #01010187;
  width: 100%;
  top: 0;
  left: 0;
  height: 100vh;
  z-index: 99;
}

.statusModal .statusModalContent {
  padding: 24px;
  max-width: 500px;
  margin: 5% auto auto;
  border-radius: 18px;
  background-color: #fff;
  border: 1px solid #dee2e6;
  animation: falldown 0.2s linear;
}

.hrModal {
  padding: 24px;
  max-width: 750px;
  background-color: #fff;
  border: 1px solid #dee2e6;
  position: absolute;
  right: 0;
  width: 100%;
  height: 100vh;
  animation: slideLeft 0.2s linear;
  overflow-y: scroll;
}

.hrModal h5 {
  font-size: 18px;
}

.hrModal .statusForm {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.hrModal .statusForm .input-group {
  width: 48%;
  margin-bottom: 24px;
  /* position: absolute; */
}

.tt-exp.label {
  width: 100%;
}

.statusForm {
  display: flex;
  gap: 10px;
}

.filter-over-write-class{
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  align-items: end;
}

.filter-over-write-class .clear-btn{
  cursor: pointer;
  &:hover{
    text-decoration: underline;
  }
}

.filter-over-write-class .input-group{
  width: 20% !important;
}

.filter-over-write-class .input-group select{
  outline: none;
}


.filter-over-write-class .input-group label{
padding-bottom: 10px;
}

.adminPanelPage {
  flex-direction: column;
}
.adminPanelPage .input-group {
  width: 100% !important;
}

.statusHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 12px !important;
  border-bottom: 1px solid #000;
  margin-bottom: 15px;
}

.statusHead div{
  display: flex;
  align-items: center;
  gap: 20px;
}


.clear_button_header {
  justify-content: space-between;
  border: none;
  align-items: center;
}

.clear_button_filter {
  text-decoration: underline;
  cursor: pointer;
}

.clear_button_filter:hover {
  color: #000;
}

.statusModal .statusForm {
  padding: 0;
}

.statusHead img {
  cursor: pointer;
}

.overview_status .overview_status_content {
  flex-direction: column;
}

.overview_status .overview_status_content .input-group {
  width: 100%;
}

.statusForm .input-group {
  width: 15%;
  gap: 0px;
  padding: 0;
  position: relative;
  margin-bottom: 0px;
}

.rs-picker-toggle-textbox {
  font-size: 14px;
}

.statusForm .input-group select,
.statusForm .date_picker_range,
.statusForm .rs-btn {
  height: 32px !important;
  font-size: 14px;
}

.statusForm .input-group label {
  margin-bottom: 2px;
  font-size: 14px;
}

.statusForm
  .input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
    .valid-feedback
  ):not(.invalid-tooltip):not(.invalid-feedback) {
  border-radius: 4px;
}

.statusForm .input-group .required__error__message {
  position: absolute !important;
  bottom: -20px !important;
}

.docImg {
  display: block;
  position: relative;
  max-width: 250px;
  margin-top: 10px;
}

.docImg img {
  width: 100%;
}

.close {
  position: absolute;
  top: -2px;
  right: -35px;
  cursor: pointer;
  max-width: fit-content;
}

iframe {
  width: 100%;
  height: 90vh;
}

.close {
  position: absolute;
  top: -10px;
  right: -10px;
  cursor: pointer;
}

.dlt-popup p {
  margin: 0;
  padding-bottom: 8px;
}

.dlt-popup {
  padding-bottom: 16px;
}

.hrModal .heading-section {
  padding-bottom: 12px;
}

.hrModal .heading-section .btn-red {
  height: fit-content;
  padding: 2px 8px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input::-webkit-search-cancel-button {
  cursor: pointer !important;
}

.view__cross__document {
  display: flex;
  justify-content: space-evenly;
}

.view__cross__document img {
  cursor: pointer;
}

.tt-exp {
  width: 48%;
  position: relative;
}

.tt-exp .required__error__message {
  position: absolute;
  /* bottom: -20px; */
  bottom: 0px;
}

.tt-exp2 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@keyframes falldown {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0%);
  }
}

@keyframes slideLeft {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0%);
  }
}

.neg {
  display: flex;
  align-items: center;
  gap: 8px;
}

.reports-container{
  margin-top: 30px;
  background: #ffffff;
  border-radius: 10px;
  padding: 20px;
}
